import { Controller } from "stimulus"
import "slick-carousel"
import "slick-carousel/slick/slick.css"
import $ from "jquery"
export default class extends Controller {
  connect() {
    let container = $(this.element);
    const cardsDefault = container.data("cardsDefault");
    const cardsSm = container.data("cardsSm");
    const cardsLsm = container.data("cardsLsm");
    const cardsMd = container.data("cardsMd");
    const cardsLmd = container.data("cardsLmd");
    const cardsLg = container.data("cardsLg");
    const cardsMxl = container.data("cardsMxl");
    const cardsXl = container.data("cardsXl");

    container.slick({
      infinite: false,
      accessibility: false,
      speed: 300,
      slidesToShow: cardsDefault,
      slidesToScroll: 1,
      prevArrow: '<button class="slick-prev fa fa-chevron-left" aria-label="Previous" type="button"></button>',
      nextArrow: '<button class="slick-next fa fa-chevron-right" aria-label="Next" type="button"></button>',
      responsive: [
        {
          breakpoint: 1340,
          settings: {
            slidesToShow: cardsXl,
          }
        },
        {
          breakpoint: 1290,
          settings: {
            slidesToShow: cardsMxl,
          }
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: cardsLg,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: cardsLmd,
            variableWidth: true
          }
        },
        {
          breakpoint: 860,
          settings: {
            slidesToShow: cardsMd,
            variableWidth: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: cardsLsm,
            variableWidth: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: cardsSm,
            variableWidth: true
          }
        },
        {
          breakpoint: 320,
          settings: "unslick"
        }
      ]
    });
  }
}
